import React, { useEffect, useState } from "react";
import { IconButton } from "@fluentui/react";
import { useSelector } from "react-redux";
import { ReduxAppState } from "../../../store/reducers";
import { SheetMusicFileUtility } from "../../../utility/v2/sheetMusicFileUtilty";
import { Song } from "../../../store/actions/songActions";

declare interface SongSheetMusicsViewProps {
  song: Song,
  onClose: () => void;
}

const SongSheetMusicsView = (props: SongSheetMusicsViewProps) => {
  const [songUris, setSongUris] = useState([] as any);
  const [selectedFile, setSelectedFile] = useState(undefined as any);
  const [hideInput, setHideInput] = useState(false);
  const userRoles = useSelector((state: ReduxAppState) => {
    return state.userAccount.userProfile?.roles;
  });

  const loadSheetMusicUrls = async () => {
    const songUris = await SheetMusicFileUtility.getSongSheetMusicFileSasUrls(props.song.id);
    if (songUris) {
      setSongUris(songUris);
    }
  };

  useEffect(() => {
    loadSheetMusicUrls();
  }, []);

  const onFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const onUploadFileProgress = (progress: number) => {
    console.log(progress);
  }
  const uploadFile = async () => {
    if (selectedFile) {
      await SheetMusicFileUtility.uploadSheetMusic(selectedFile, props.song.title1, onUploadFileProgress);
      alert("歌谱文件已上传");
      setSelectedFile(undefined);
      await loadSheetMusicUrls();

      setHideInput(true);
      setHideInput(false);
    }
  };

  const deleteFile = async (blobName: any) => {
    if (window.confirm("Are you sure to delete this file?")) {
      await SheetMusicFileUtility.deleteSheetMusicFile(blobName)
      alert("成功删除歌谱文件。");
      await loadSheetMusicUrls();
    }
  };

  return (
    <>
      <div
        style={ {
          marginBottom: 15,
          color: "blue",
          cursor: "pointer",
          float: "right",
          display: "inline-block",
          width: "100%",
        } }
        onClick={ () => {
          props.onClose();
        } }
      >
        退出
      </div>
      <div>
        { songUris &&
          songUris.map((item: any, index: any) => {
            return (
              <div key={ index }>
                <a
                  target="_blank"
                  href={ item.uri }
                  style={ { color: "blue", cursor: "pointer" } }
                >
                  { item.blobName }{ " " }
                </a>
                <IconButton
                  iconProps={ { iconName: "Delete" } }
                  onClick={ () => deleteFile(item.blobName) }
                ></IconButton>
              </div>
            );
          }) }
      </div>
      <div style={ { marginBottom: 15 } }>
        { !hideInput && (
          <input
            type="file"
            onChange={ onFileChange }
            disabled={ userRoles <= 0 }
          ></input>
        ) }
      </div>
      <div>
        <button onClick={ uploadFile } disabled={ !selectedFile || userRoles <= 0 }>
          上传歌谱
        </button>
      </div>
    </>
  );
};

export default SongSheetMusicsView;
