import React, { useState } from "react";
import { IconButton } from "@fluentui/react";
import { BibleSearchUtility } from "../../../utility/bibleSearchUtility";

const BibleSearchView = (props: {
    onVersesReturned: (verses: {
        volumnNumber: number,
        chapterNumber: number,
        verseNumber: number
    }[]) => void
}) => {
    const [query, setQuery] = useState("");

    const changeQuery = (q: string) => {
        setQuery(q);
    };

    const search = async () => {
        const verses = await BibleSearchUtility.searchBible(query);
        props.onVersesReturned(verses);
    };

    const searchKeyDown = (event: any) => {
        if (event.keyCode === 13) {
            search();
        }
    };

    return (
        <div style={ { display: "flex" } }>
            <input
                onChange={ event => changeQuery(event.target.value) }
                onKeyDown={ searchKeyDown }
            ></input>
            <IconButton
                onClick={ () => { search() } }
                title="搜索"
                iconProps={ { iconName: "Search" } }></IconButton>
        </div>
    );
}

export default BibleSearchView