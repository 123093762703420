import { DefaultButton, Modal } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ReduxAppState } from "../../../store/reducers";
import { UserRolesEnum, UserService } from "../../../utility/userService";
import { SongUtilityV2 } from "../../../utility/v2/songUtility";

const SongDetailView = () => {
  const [tags, setNewTags] = useState("");
  const [youtubeUrl1, setYoutubeUrl1] = useState("");
  const [youtubeUrl2, setYoutubeUrl2] = useState("");

  const userRoles = useSelector((state: ReduxAppState) => {
    return state.userAccount.userProfile?.roles;
  });
  const activeSong = useSelector((state: ReduxAppState) => {
    return state.song.activeSong;
  });

  useEffect(() => {
    if (activeSong) {
      setNewTags(activeSong.tags);
      const youtubeUrls = activeSong.youtubeUrls;

      if (youtubeUrls) {
        if (youtubeUrls.length > 0) {
          setYoutubeUrl1(youtubeUrls[0]);
        };
        if (youtubeUrls.length > 1) {
          setYoutubeUrl2(youtubeUrls[1]);
        };
      }
    }
  }, [activeSong]);

  const saveSong = async () => {
    const youtubeUrls = [];
    if (youtubeUrl1) {
      youtubeUrls.push(youtubeUrl1);
    }
    if (youtubeUrl2) {
      youtubeUrls.push(youtubeUrl2);
    }

    let newSong = {
      ...activeSong,
      youtubeUrls: youtubeUrls,
      tags: tags
    };

    await SongUtilityV2.updateSong(newSong);
    alert("update succeed.");
  };

  return (
    <div style={ { width: "100%" } }>
      <pre
        style={ {
          height: 300,
          maxWidth: 500,
          display: "inline-block",
          float: "left",
          width: "50%",
          overflow: "scroll",
          resize: "both"
        } }
      >
        { activeSong.lyrics }
      </pre>
      <div style={ { display: "inline-block", paddingLeft: 10, width: "50%" } }>
        <div style={ { marginTop: 10, marginBottom: 10 } }>
          唱法:
          <span>{ activeSong.sequence ? activeSong.sequence : "N/A" }</span>
        </div>
        { UserService.AuthorizeUserRole(userRoles, UserRolesEnum.Read) && (
          <>
            <div
              style={ {
                marginTop: 10,
                marginBottom: 10,
                wordWrap: "break-word",
                display: "inline-block"
              } }
            >
              Tags (赞美真神, 颂扬主恩, 回应主爱, 祈祷感恩, 悔改认罪, 跟随顺服,
              与主亲近, 为主而活, 就近十架, 彼此相爱, 复活盼望)
            </div>
            <input
              size={ 50 }
              value={ tags }
              onChange={ event => {
                setNewTags(event.target.value);
              } }
            ></input>
            <div style={ { marginTop: 10, marginBottom: 10 } }>
              Youtube Url / Thumbnail
            </div>
            <input
              size={ 50 }
              value={ youtubeUrl1 }
              onChange={ event => {
                setYoutubeUrl1(event.target.value);
              } }
            ></input>
            <input
              size={ 50 }
              value={ youtubeUrl2 }
              onChange={ event => {
                setYoutubeUrl2(event.target.value);
              } }
            ></input>
          </>
        ) }
        { UserService.AuthorizeUserRole(userRoles, UserRolesEnum.Read) && (
          <div style={ { marginTop: 10, marginBottom: 10 } }>
            <DefaultButton
              text={ "Save" }
              onClick={ saveSong }
              disabled={ userRoles <= 0 }
            ></DefaultButton>
          </div>
        ) }
      </div>
    </div>
  );
};

export default SongDetailView;
