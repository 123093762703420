import React from "react";
import { ChineseBible } from "./bibleContent/bible_chinese";
import BibleVerseView from "./BibleVerseView";
import { EnglishBible } from "./bibleContent/bible_english";
import { BibleVolumns } from "./bibleContent/bible_volumns";

const BibleChapterView = (props: {
    volumnIndex: number,
    chapterNumber: number,
    startVerseNumber: number,
    endVerseNumber: number,
    hideTitle?: boolean
}) => {
    const verses1 = ChineseBible.filter(x => x.volumn === props.volumnIndex + 1 && x.chapter === props.chapterNumber);
    const verses2 = EnglishBible.filter(x => x.volumn === props.volumnIndex + 1 && x.chapter === props.chapterNumber);
    return (
        <div>
            { !props.hideTitle && <div>{ "第 " + props.chapterNumber + " 章" }</div> }
            { verses1.map((item, index) => {
                if (index >= props.startVerseNumber - 1 && index <= props.endVerseNumber - 1) {
                    return <div style={ { marginBottom: "3px" } }>
                        <BibleVerseView
                            volumnIndex={ props.volumnIndex }
                            chapterNumber={ props.chapterNumber }
                            verseNumber={ index + 1 }
                            verse={ item.verse }
                            hideVerseNumber={ false }
                            showFullVerseNumber={ props.hideTitle } />
                        <div style={ { marginBottom: "3px" } } />
                        <BibleVerseView
                            volumnIndex={ props.volumnIndex }
                            chapterNumber={ props.chapterNumber }
                            verseNumber={ index + 1 }
                            verse={ verses2[index].verse }
                            hideVerseNumber={ true }
                            showFullVerseNumber={ props.hideTitle } />
                    </div>
                };
            }) }

        </div>
    );
};

export default BibleChapterView;
