import React, { useState } from "react";
import { BibleVolumns } from "./bibleContent/bible_volumns";
import { Dropdown, IDropdownOption } from "@fluentui/react";

const BibleVolumnsView = (props: {
    volumnIndex: number,
    onBibleVolumnSelected: (volumnName: number) => void
}) => {
    const populateVolumnOptions = (): IDropdownOption[] => {
        const options = [];
        for (let index = 0; index < BibleVolumns.length; index++) {
            options.push(
                {
                    key: index,
                    text: BibleVolumns[index][2]
                } as IDropdownOption
            );
        }

        return options;
    }

    const onChange = (_event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, _index?: number): void => {
        if (option) {
            props.onBibleVolumnSelected(option.key as number);
        }
    };

    return (
        <div>
            <Dropdown
                placeholder="选择圣经"
                label="选择圣经1"
                defaultSelectedKey={ props.volumnIndex }
                options={ populateVolumnOptions() }
                onChange={ onChange }
            />
        </div>
    );
}

export default BibleVolumnsView