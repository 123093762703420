import React from "react";
import BibleChapterView from "../BibleChapterView";

const BibleRandomVersesView = (props: {
    verses: {
        volumnNumber: number,
        chapterNumber: number,
        verseNumber: number
    }[]
}) => {
    return (
        <div style={ { display: "flex", flexDirection: "column" } }>
            { props.verses && props.verses.map(
                (item, index) => {
                    return <div
                        style={ {
                            width: "100%"
                        } }>
                        <BibleChapterView
                            startVerseNumber={ item.verseNumber }
                            endVerseNumber={ item.verseNumber }
                            volumnIndex={ item.volumnNumber - 1 }
                            chapterNumber={ item.chapterNumber }
                            hideTitle={ true } />
                    </div>
                })
            }
        </div>
    );
}

export default BibleRandomVersesView