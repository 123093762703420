import React, { Fragment, useEffect, useState } from "react";
import styles from "./Songs.module.css";
import Pagination from "../shared/pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { ReduxAppState } from "../../store/reducers";
import { SongsViewCommandBarView } from "./SongsViewCommandBarView";
import { SongUtilityV2 } from "../../utility/v2/songUtility";
import { setSongSearchResult, setSongs } from "../../store/actions/songActions";
import SongsPageView from "./SongsPageView";
import { IconButton } from "@fluentui/react";

const SongsView = () => {
  const [query, setQuery] = useState("1");
  const [top, setTop] = useState(10);
  const [skip, setSkip] = useState(0);
  const dispatch = useDispatch();
  const userRoles = useSelector((state: ReduxAppState) => { return state.userAccount.userProfile?.roles });
  const songSearchResult = useSelector((state: ReduxAppState) => { return state.song.songSearchResult });

  const loadSongsFromAzureTable = async () => {
    const songs = await SongUtilityV2.getAllSongs();
    dispatch(setSongs(songs));
    const newSongTitles = [];

    for (let index = 0; index < songs.length; index++) {
      newSongTitles.push(songs[index].title1);
    }
    dispatch(setSongSearchResult(newSongTitles));
    setSkip(0);
  };

  useEffect(() => {
    loadSongsFromAzureTable();
  }, []);

  const search = async () => {
    const songTitles = await SongUtilityV2.searchSongs(query);
    dispatch(setSongSearchResult(songTitles));
    setSkip(0);
  };

  const changeQuery = (q: string) => {
    setQuery(q);
  };

  const searchKeyDown = (event: any) => {
    if (event.keyCode == 13) {
      search();
    }
  };

  const selectPage = (skip: number) => {
    setSkip(skip);
  };

  let totalItems = songSearchResult && songSearchResult.length > 0 ? songSearchResult.length : 0;
  const refreshSongs = async () => {
    await SongUtilityV2.CopyEasySlidesSongsToAzure();
    await loadSongsFromAzureTable();
  }

  return (
    <Fragment>
      <div style={ { height: "100%" } }>
        <div style={ { height: 30, paddingLeft: 10, paddingRight: 10, marginTop: 10, marginBottom: 10 } }>
          <input
            className={ styles.songsearchbox }
            onChange={ event => changeQuery(event.target.value) }
            onKeyDown={ searchKeyDown }
          ></input>
          <IconButton
            onClick={ () => { search() } }
            title="搜索"
            iconProps={ { iconName: "Search" } }></IconButton>
          <SongsViewCommandBarView
            playDisabled={ true }
            refreshDisabled={ userRoles <= 0 }
            onPlay={ () => { } }
            onRefresh={ refreshSongs } />
        </div>
        <SongsPageView top={ top } skip={ skip } />
        <div style={ { height: 30, paddingLeft: 10, paddingRight: 10 } }>
          <Pagination
            totalItems={ totalItems }
            skip={ skip }
            top={ top }
            selectPage={ selectPage }
          ></Pagination>
        </div>
      </div>
    </Fragment>
  );
}

export default SongsView;
