import React from "react";
import { BibleVolumns } from "./bibleContent/bible_volumns";

const BibleVerseView = (props: {
    volumnIndex: number,
    chapterNumber: number,
    verseNumber: number,
    verse: string,
    hideVerseNumber: boolean,
    showFullVerseNumber?: boolean
}) => {
    const renderTitle = () => {
        const title = props.showFullVerseNumber ?
            BibleVolumns[props.volumnIndex][2] + " " + props.chapterNumber + ":" + props.verseNumber + " " :
            props.verseNumber;

        if (props.showFullVerseNumber) {
            return <a style={ { fontWeight: "bold" } } target="_blank" href={ "/bible?volumnindex=" + props.volumnIndex + "&chapternumber=" + props.chapterNumber }>{ title }</a>
        } else {
            return <span style={ { width: "25px", display: "inline-block" } }>{ title }</span>
        }
    }

    return (
        <div>
            { !props.hideVerseNumber && renderTitle() }
            { props.verse }
        </div>
    );
};

export default BibleVerseView;
