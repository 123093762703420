import React, { useEffect, useState } from "react";
import { BibleVolumns } from "./bibleContent/bible_volumns";
import BibleChapterView from "./BibleChapterView";
import BibleVolumnsView from "./BibleVolumnsView";
import BibleNotesView from "./notes/BibleNotesView";
import BibleSearchView from "./bibleSearch/BibleSearchView";
import BibleRandomVersesView from "./bibleSearch/BibleRandomVersesView";
import { useLocation } from 'react-router-dom';

const BibleView = () => {
    const [selectedVolumnIndex, setSelectedVolumn] = useState(0);
    const [selectedChapterNumber, setSelectedChapter] = useState(1);
    const [verseSearchResult, setVerseSearchResult] = useState<{
        volumnNumber: number,
        chapterNumber: number,
        verseNumber: number
    }[]>();

    const location = useLocation();
    const readQueryStrings = () => {
        const search = location.search;
        const volumnIndexValue = new URLSearchParams(search).get('volumnindex');
        const chapterNumberValue = new URLSearchParams(search).get('chapternumber');

        if (volumnIndexValue && chapterNumberValue) {
            const volumnIndex = parseInt(volumnIndexValue);
            const chapterNumber = parseInt(chapterNumberValue);

            if (volumnIndex >= 0 && chapterNumber >= 1) {
                setSelectedVolumn(volumnIndex);
                setSelectedChapter(chapterNumber);
            }
        }
    };

    useEffect(() => {
        readQueryStrings();
    }, []);


    const renderChapters = () => {
        const chapters = [];
        const totalChapters = (BibleVolumns[selectedVolumnIndex][3]) as any;

        for (let index = 0; index < totalChapters; index++) {
            chapters.push(
                <span
                    style={ { display: "inline-block", padding: "2px", color: "blue", cursor: "pointer" } }
                    key={ index }
                    onClick={ () => {
                        setSelectedChapter(index + 1)
                    } }
                >
                    { index + 1 }
                </span>
            );
        }

        return chapters;
    }

    const onBibleVolumnSelected = (volumn: number) => {
        setSelectedVolumn(volumn);
        setVerseSearchResult(undefined);
    };

    const onVersesReturned = (verses: {
        volumnNumber: number,
        chapterNumber: number,
        verseNumber: number
    }[]) => {
        setVerseSearchResult(verses);
    }

    return (
        <>
            <div style={ { display: "flex" } }>
                <div style={ { width: "50%", display: "inline-block", padding: "5px" } }>
                    <BibleSearchView onVersesReturned={ onVersesReturned } />
                    <BibleVolumnsView volumnIndex={ selectedVolumnIndex } onBibleVolumnSelected={ onBibleVolumnSelected } />
                    { !verseSearchResult &&
                        <>
                            <div style={ { display: "inline-block" } }>
                                { renderChapters() }
                            </div>
                            <BibleChapterView
                                startVerseNumber={ 1 }
                                endVerseNumber={ 9999 }
                                volumnIndex={ selectedVolumnIndex }
                                chapterNumber={ selectedChapterNumber } />
                        </>
                    }
                    { verseSearchResult && <BibleRandomVersesView verses={ verseSearchResult } /> }
                </div>
                <div style={ { width: "50%", display: "inline-block" } }>
                    <BibleNotesView volumnName={ BibleVolumns[selectedVolumnIndex][2] as string } chapterNumber={ selectedChapterNumber } />
                </div>
            </div>
        </>
    );
}

export default BibleView